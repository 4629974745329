import React from "react";
import Hero from "../components/Hero";

function Team() {
  const teamHeroContent = (
    <>
      <ul className="team-hero-list">
        <li className="team-hero-list-item">Committed</li>
        <li className="team-hero-list-item">Passionate</li>
        <li className="team-hero-list-item">Authentic</li>
        <li className="team-hero-list-item">Experienced</li>
      </ul>
      <p className="team-hero-subtitle">We maintain a personal and honest approach to delivering results</p>
    </>
  );

  return (
    <>
      <Hero content={teamHeroContent} background="team-hero" />
      <p className="pageContent">hello, leadership team page</p>
    </>
  );
}

export default Team;
